@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Regular.eot");
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("../public/fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Regular.woff") format("woff"),
    url("../public/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraboldItalic.eot");
  src: local("Gilroy Extrabold Italic"), local("Gilroy-ExtraboldItalic"),
    url("../public/fonts/Gilroy-ExtraboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-ExtraboldItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-ExtraboldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("../public/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Bold.woff") format("woff"),
    url("../public/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("../public/fonts/Gilroy-Black.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Black.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Black.woff") format("woff"),
    url("../public/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Light.eot");
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("../public/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Light.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Light.woff") format("woff"),
    url("../public/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Semibold.eot");
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("../public/fonts/Gilroy-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Semibold.woff") format("woff"),
    url("../public/fonts/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Medium.eot");
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("../public/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Medium.woff") format("woff"),
    url("../public/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-MediumItalic.eot");
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("../public/fonts/Gilroy-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-MediumItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-MediumItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BlackItalic.eot");
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("../public/fonts/Gilroy-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-BlackItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-BlackItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLight.eot");
  src: local("Gilroy UltraLight"), local("Gilroy-UltraLight"),
    url("../public/fonts/Gilroy-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-UltraLight.woff2") format("woff2"),
    url("../public/fonts/Gilroy-UltraLight.woff") format("woff"),
    url("../public/fonts/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-RegularItalic.eot");
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("../public/fonts/Gilroy-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-RegularItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-RegularItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiboldItalic.eot");
  src: local("Gilroy Semibold Italic"), local("Gilroy-SemiboldItalic"),
    url("../public/fonts/Gilroy-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-SemiboldItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-SemiboldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-HeavyItalic.eot");
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("../public/fonts/Gilroy-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-HeavyItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-HeavyItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Extrabold.eot");
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("../public/fonts/Gilroy-Extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Extrabold.woff") format("woff"),
    url("../public/fonts/Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BoldItalic.eot");
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("../public/fonts/Gilroy-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-BoldItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-BoldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLightItalic.eot");
  src: local("Gilroy UltraLight Italic"), local("Gilroy-UltraLightItalic"),
    url("../public/fonts/Gilroy-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-UltraLightItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-UltraLightItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-LightItalic.eot");
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"),
    url("../public/fonts/Gilroy-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-LightItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-LightItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Heavy.eot");
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("../public/fonts/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Heavy.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Heavy.woff") format("woff"),
    url("../public/fonts/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Thin.eot");
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("../public/fonts/Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Thin.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Thin.woff") format("woff"),
    url("../public/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ThinItalic.eot");
  src: local("Gilroy Thin Italic"), local("Gilroy-ThinItalic"),
    url("../public/fonts/Gilroy-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Gilroy-ThinItalic.woff2") format("woff2"),
    url("../public/fonts/Gilroy-ThinItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* rubik-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-500italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-500italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 600;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 700;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 800;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
/* rubik-900italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 900;
  src: url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v21-latin-ext_latin_cyrillic-ext_cyrillic-900italic.svg#Rubik")
      format("svg"); /* Legacy iOS */
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: Gilroy;
}

.button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;

  background: #338ae6;
  border-radius: 3rem;

  display: inline-block;
  padding: 1.5rem 2rem;
  margin: 1.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.2s all linear;

  &:hover {
    background: #1f73cb;
  }
}

.inputs {
  display: flex;
  flex-wrap: wrap;

  .input-holder {
    min-width: 15rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      flex: 1;
      display: flex;
      margin: 0.5rem;
    }
  }
}

.small-text {
  display: flex;
  flex: 1;
  padding: 1.5rem 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 165%;
  color: #595858;

  backdrop-filter: blur(4px);

  a {
    color: #595858;
  }
}
.text-input {
  display: flex;
  flex: 1;
  padding: 1.5rem 2rem;
  line-height: 1.5rem;
  align-items: center;
  border: 0.0625rem solid #dedede;
  border-radius: 3rem;
  margin: 0.5rem;

  outline: none;
  &:active,
  &:focus {
    border-color: #338ae5;
  }
}
.urt {
  max-width: 94rem;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  > .small-card {
    min-width: 15rem;
    display: flex;
  }

  .plate {
    display: flex;
    svg {
      width: 100%;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
      margin: 1rem 0;
    }
    .phone {
      margin: 1rem;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2rem;
      text-align: right;

      .phone-link {
        display: none;
        text-decoration: none;
        color: #000000;
      }

      .arrange-call {
        color: #338ae5;
        display: flex;
        flex-direction: row;
        font-size: 1.125rem;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        svg.MuiSvgIcon-root {
          font-size: 1.125rem;
          margin-right: 0.5rem;
        }

        .text {
          border-bottom: 0.0625rem dashed #338ae5;
          padding: 0;
        }
      }
    }
  }
  .underlined {
    position: relative;
    white-space: nowrap;
    .underline {
      position: absolute;
      left: 0;
      right: 0;
      top: 95%;
      width: 100%;
      height: 30%;
    }
  }

  .hero-header {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 3.5rem;
    line-height: 4.5rem;
    z-index: 2;

    color: #595858;

    &.small {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    &.smallest {
      margin-top: 5rem;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
    &.center {
      text-align: center;
    }
  }
  .logos {
    display: flex;
    margin: 1rem auto;
    flex-direction: row;
    max-width: 80rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .logo {
      height: 6rem;
      width: 14rem;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .logo-holder {
      width: 16rem;
      height: 8rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .small-cards {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;

    .small-card {
      flex: 1;
      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }
  .clients-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .sub-header {
      padding: 2rem;
    }
  }
  .sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    z-index: 2;
    margin-bottom: 2rem;

    color: #595858;
    &.center {
      text-align: center;
    }
    &.padded {
      margin: 0;
    }
  }

  .footer {
    margin-bottom: 3rem;
  }
  > .halfs {
    flex-wrap: wrap;
    > div {
      min-width: 20rem;
    }
    .left {
      padding: 2rem;
      svg {
        width: 100%;
      }
    }
    .right {
      svg {
        width: 100%;
      }
    }
  }

  .card {
    background: #f2f9ff;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    margin: 2rem 0;
    position: relative;

    .close {
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: 2rem;
      top: 2rem;
    }

    .header {
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 400;
      font-size: 1.75rem;
      line-height: 125%;
    }
    .items-holder {
      .item {
        margin: 2rem 0;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 145%;
      }
    }
  }
  .small-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fafafa;
    border-radius: 0.5rem;
    margin: 0.5rem 1rem;
    min-width: 15rem;

    &.padded {
      padding: 3rem;
    }

    &:first-child {
      margin-top: 3rem;
    }
    .icon {
      margin: 1rem;
      flex-shrink: 0;
      svg {
        flex-shrink: 0;
        width: 2rem;
      }
    }
    .text {
      margin: 1rem;
    }
  }
  .store-holder {
    display: flex;
    flex-direction: row;

    .store-button {
      &:first-child {
        margin-right: 1rem;
      }
      display: flex;
      a {
        max-width: 100%;
        display: block;
        > svg {
          width: 100%;
        }
      }
    }
  }
  .text {
    line-height: 1.5rem;
    padding: 1rem 0;
    &.small {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .padded {
    padding: 1rem 0;
  }
  .pointer {
    cursor: pointer;
  }
  .sub-item {
    margin-left: 2rem;
    font-size: 85%;
    padding: 0.25rem 0;

    &:first-child {
      margin-top: 1rem;
    }
  }
  .list-item {
    display: flex;
    flex-direction: row;
    border-bottom: 0.0625rem solid #ededed;
    .bullit {
      margin: 1rem;
      flex-shrink: 0;
      svg {
        width: 2rem;
      }
    }
    .text {
      line-height: 1.5rem;
      padding: 1rem 0;

      &.full {
        flex: 1;
      }
    }
  }

  svg.daub {
    position: absolute;
    left: 15%;
    bottom: 0;
  }
  svg.sun {
    right: 0;
    position: absolute;
    top: 50%;
    margin-top: -6rem;
  }
  svg.arrow-left {
    position: absolute;
    top: 0;
    left: 5rem;
  }
  .halfs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    > div {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      min-width: 20rem;

      &.third {
        max-width: 25%;

        .item {
          margin: 1rem;
          border-bottom: 0.0625rem solid #42aa3c;

          .number {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 92%;

            color: #42aa3c;
          }
        }
      }
    }
    .image {
      flex: 1;
      min-height: 15rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .slider {
    height: min(42rem, 50vw);
    position: relative;
    overflow: hidden;

    .pager-holder {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;

      .page {
        width: 4rem;
        margin: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        background-color: #ddd;

        &.current {
          background-color: #338ae5;
        }
      }
    }
    .slides-holder {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      transition: 2s all ease-in-out;

      &.page-1 {
        left: 0;
      }
      &.page-2 {
        left: -100%;
      }
      &.page-3 {
        left: -200%;
      }
      &.page-4 {
        left: -300%;
      }
      &.page-5 {
        left: -400%;
      }

      .slide {
        flex-shrink: 0;
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .arrow {
          position: absolute;
          bottom: calc(50% + 3rem);
          left: 50%;
          width: 16rem;
          display: flex;
          margin-left: -8rem;
        }
        .text {
          width: 55%;
          bottom: 50%;
          left: 0;

          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 3.5rem;
          line-height: 4.5rem;
          z-index: 2;

          color: #595858;
        }

        .image {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          top: 0;
          overflow: hidden;
          background-position: right;
          background-repeat: no-repeat;
          background-size: auto min(42rem, 50vw);
        }
      }
    }
  }
}

@media (max-width: 1490px) {
  .urt .slider .slides-holder .slide .arrow {
    bottom: calc(50% + 6rem);
  }
}

@media (max-width: 1200px) {
  .urt .slider .slides-holder .slide .text {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .urt .slider .slides-holder .slide .arrow {
    bottom: calc(50% + 3rem);
    width: 10rem;
    display: flex;
  }
  .urt .hero-header {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 800px) {
  .urt .slider .slides-holder .slide .text {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .urt > .small-card {
    .hero-header.small {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
    .sub-header {
      font-size: 0.75rem;
      line-height: 1.5rem;
    }
  }
  .urt .small-card.padded {
    padding: 1.5rem;
  }
  .urt .halfs > div {
    min-width: 15rem;
  }
}

@media (max-width: 600px) {
  .urt .hero-header {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .urt .slider .pager-holder .page {
    width: 2rem;
    margin: 0.25rem;
    height: 0.25rem;
    border-radius: 0.5rem;
    background-color: #ddd;
  }
  .urt .header .phone {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .urt .slider .slides-holder .slide .text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .urt .slider .slides-holder .slide .arrow {
    left: 70%;
    bottom: calc(50% + 0rem);
    width: 6rem;
  }
}

@media (max-width: 510px) {
  .urt .halfs > div.third {
    max-width: none;
  }

  .urt .improvements .right {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .daub {
      width: 4rem;
      left: auto;
      right: 5vw;
    }
    .sun {
      width: 3rem;
      left: 0;
      right: auto;
      top: 15vh;
    }
    .arrow-left {
      width: 3rem;
      top: 10vh;
      left: 50vw;
    }
  }
}
